import { addEventListenerOnce } from '../utils/event.js'

export class DropdownMenu extends HTMLElement {
  constructor () {
    super()
    this.options = {
      animationDelay: 150
    }
    this.button = this.querySelector('button')
    this.targetID = this.getAttribute('target')
    if (this.targetID) {
      this.targetEL = document.getElementById(this.targetID)
    } else {
      // console.log('No target attribute found. Using the first ul element as target.')
      this.targetEL = this.querySelector('ul')
    }

    // If the targetEl has no id, generate one
    if (!this.targetEL.id) {
      this.targetID = `dropdown-${Math.random().toString(36).substring(2, 9)}`
      this.targetEL.id = this.targetID
    }
  }

  connectedCallback () {
    this.button.setAttribute('aria-expanded', 'false')
    this.button.setAttribute('aria-controls', this.targetEL.id)
    this.targetEL.setAttribute('aria-hidden', 'true')
    addEventListenerOnce(this, 'click', this.toggle.bind(this))
  }

  toggle () {
    if (this.targetEL.getAttribute('aria-hidden') === 'true') {
      this.show()
    } else {
      this.hide()
    }
  }

  show () {
    this.targetEL.setAttribute('aria-hidden', 'false')
    this.button.setAttribute('aria-expanded', 'true')

    // setTimeout with a delay of 0 is used to put this.toggleOutsideClickHandler() at the
    // back of the JavaScript event queue. This means it will run after the current call stack has cleared,
    // and thus only after the next click event, not the current one. Otherwise, the menu would be closed
    // immediately after it was opened.
    setTimeout(() => {
      this._toggleExternalHandler()
    }, 150)
  }

  hide () {
    this._close()
  }

  _close () {
    this.targetEL.classList.add('closing')

    setTimeout(() => {
      this.targetEL.classList.remove('closing')
      this.targetEL.setAttribute('aria-hidden', 'true')
      this.button.setAttribute('aria-expanded', 'false')

      setTimeout(() => {
        this._toggleExternalHandler()
      }, 150);

    }, this.options.animationDelay)
  }

  _onClickOutside () {
    const self = this
    return (e) => {
      if (!self.targetEL.contains(e.targetEL)) {
        self._close()
      }
    }
  }

  _toggleExternalHandler () {
    if (!this.targetEL.externalHandler &&
      this.button.getAttribute('aria-expanded') === 'true') {
      this.targetEL.externalHandler = this._onClickOutside()
      document.addEventListener('click', this.targetEL.externalHandler)
    } else if (this.targetEL.externalHandler && this.button.getAttribute('aria-expanded') === 'false') {
      document.removeEventListener('click', this.targetEL.externalHandler)
      delete this.targetEL.externalHandler
    }
  }
}
