import { DropdownMenu } from './dropdown-menu.js'

export class MobileMenu extends DropdownMenu {
  constructor () {
    super()

    this.closeButton = this.querySelector('.navbar-close > button')
  }

  connectedCallback () {
    super.connectedCallback()
    if (this.closeButton) {
      this.closeButton.addEventListener('click', this.hide.bind(this))
    }

    // Listen for resize events and remove the aria-hidden attribute from the mobile menu if it is present
    window.addEventListener('resize', () => {
      if (window.innerWidth >= 768) {
        this.hide()
      }
    })
  }
}
