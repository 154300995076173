/**
 * Event Symbols
 * @type {{}}
 */
const eventSymbols = {}

/**
 * Add Event Listener Once
 *
 * This function is used to add an event listener to an element only once. It is used in the
 * library to prevent duplicate event listeners from being added to the same element. If the
 * element has already been initialized, the event listener will not be added. If the element
 * has not been initialized, the event listener will be added, but will be removed first to ensure
 * that it is only added once.
 *
 * Usage:
 * addEventListenerOnce(button, 'click', handler, false);
 *
 * @param element
 * @param event
 * @param handler
 * @param options
 */
export function addEventListenerOnce (element, event, handler, options) {
  // Create a new symbol for this event if one does not already exist
  if (!eventSymbols[event]) {
    eventSymbols[event] = Symbol('fet-' + event)
  }

  const eventSymbol = eventSymbols[event]

  // If the element is not marked as initialized, add the event listener
  if (!element[eventSymbol]) {
    // If this element hasn't been initialized, remove any pre-existing event listeners first (idempotent)
    element.removeEventListener(event, handler, options)

    // Then add the event listener
    element.addEventListener(event, handler, options)

    // Mark this element as initialized
    element[eventSymbol] = true
  }
}
